@import "../../../styles/functions.scss";

.image-icon {
  width: 100%;
  height: 100%;
  background-size: contain !important;

  &.icon-target-active {
    background: icon("icon-target-active", "svg");
  }
  &.icon-add-white {
    background: icon("icon-add-white", "svg");
  }
  &.icon-target-journey {
    background: icon("icon-target-journey", "svg");
  }
  &.icon-simulate-inactive {
    background: icon("icon-simulate-inactive", "svg");
  }
  &.icon-simulate-active {
    background: icon("icon-simulate-active", "svg");
  }
  &.icon-simulate-journey {
    background: icon("icon-simulate-journey", "svg");
  }
  &.icon-impact-inactive {
    background: icon("icon-impact-inactive", "svg");
  }
  &.icon-impact-active {
    background: icon("icon-impact-active", "svg");
  }
  &.icon-impact-journey {
    background: icon("icon-impact-journey", "svg");
  }
  &.icon-shipment-active {
    background: icon("icon-shipment-active", "svg");
  }
  &.icon-shipment-inactive {
    background: icon("icon-shipment-inactive", "svg");
  }
  &.icon-new-campaign {
    background: icon("icon-create", "svg");
  }
  &.icon-suggestions {
    background: icon("icon-suggestion", "png");
  }

  &.icon-import {
    background: icon("icon-import", "svg");
  }
  &.icon-folder {
    background: icon("icon-folder", "svg");
  }
  &.icon-microsegment {
    background: icon("icon-microsegment", "svg");
  }

  &.icon-re-execute-normal {
    background: icon("icon-re-execute-normal");
  }
  &.icon-search-grey {
    background: icon("icon-search-grey");
  }
  &.icon-trending-down {
    background: icon("icon-trending-down");
  }
  &.icon-refresh {
    background: icon("icon-refresh");
  }
  &.img-create-campaign {
    background: image("icon-targetlist-home", "svg");
  }
  &.img-manage-campaign {
    background: image("img-manage-campaign");
  }
  &.img-file-upload-logo {
    background: image("img-file-upload-logo");
  }
  &.icon-upload {
    background: image("icon-upload", "svg");
  }
  &.img-drag-and-drop {
    background: image("img-drag-and-drop", "png");
  }
  &.img-csv-file {
    background: image("img-csv-file", "png");
  }
  &.icon-close {
    background: icon("icon-close", "png");
  }
  &.icon-menu-close {
    background: icon("icon-menu-close", "svg");
  }
  &.icon-FR {
    background: icon("icon-france", "png");
  }
  &.icon-CA {
    background: icon("icon-canada", "png");
  }
  &.icon-CH {
    background: icon("icon-switzerland", "png");
  }
  &.icon-DE {
    background: icon("icon-germany", "png");
  }
  &.icon-IT {
    background: icon("icon-italy", "png");
  }
  &.icon-UK {
    background: icon("icon-uk", "png");
  }
  &.icon-GB {
    background: icon("icon-uk", "png");
  }
  &.icon-ES {
    background: icon("icon-spain", "png");
  }
  &.icon-TR {
    background: icon("icon-turkey", "png");
  }
  &.icon-PL {
    background: icon("icon-poland", "png");
  }
  &.icon-NO {
    background: icon("icon-no", "png");
  }
  &.icon-BE {
    background: icon("icon-be", "png");
  }
  &.icon-FI {
    background: icon("icon-fi", "png");
  }
  &.icon-JP {
    background: icon("icon-jp", "png");
  }
  &.icon-DK {
    background: icon("icon-dk", "png");
  }
  &.icon-LT {
    background: icon("icon-lt", "png");
  }
  &.icon-LV {
    background: icon("icon-lv", "png");
  }
  &.icon-RU {
    background: icon("icon-ru", "png");
  }
  &.icon-SE {
    background: icon("icon-se", "png");
  }
  &.icon-AT {
    background: icon("icon-at", "png");
  }
  &.icon-NL {
    background: icon("icon-nl", "png");
  }
  &.icon-GR {
    background: icon("icon-gr", "png");
  }
  &.icon-delete {
    background: icon("icon-delete", "svg");
  }
  &.icon-drag-and-drop-blue {
    background: icon("icon-drag-and-drop-blue", "svg");
  }
  &.icon-error {
    background: icon("icon-caution", "svg");
  }
  &.icon-file-upload {
    background: icon("icon-file-upload", "svg");
  }
  &.icon-bell {
    background: icon("icon-bell", "svg");
  }
  &.icon-info {
    background: icon("icon-info", "svg");
  }
  &.icon-arrow-right {
    background: icon("icon-arrow-right", "svg");
  }
  &.icon-arrow-right-active {
    background: icon("icon-arrow-right-active", "svg");
  }
  &.icon-arrow-inactive {
    background: icon("icon-gray-arrow", "svg");
  }
  &.icon-arrow-active {
    background: icon("icon-blue-arrow", "svg");
  }
  &.icon-success {
    background: icon("icon-success", "svg");
  }
  &.icon-download-blue {
    background: icon("icon-download-blue", "svg");
  }
  &.icon-blue-smile {
    background: icon("icon-blue-smile", "svg");
  }
  &.icon-orange-smile {
    background: icon("icon-orange-smile", "svg");
  }
  &.icon-green-smile {
    background: icon("icon-green-smile", "svg");
  }
  &.icon-logout {
    background: icon("icon-logout", "svg");
  }
  &.icon-target-success {
    background: icon("icon-target-success", "svg");
  }
  &.icon-right-arrow-gray {
    background: icon("icon-right-arrow-gray", "svg");
  }
  &.icon-right-arrow-blue {
    background: icon("icon-right-arrow-blue", "svg");
  }
  &.icon-down-arrow-black {
    background: icon("icon-dropdown-arrow-black", "svg");
  }
  &.icon-right-arrow {
    background: icon("icon-right-arrow", "svg");
  }
  &.icon-left-arrow {
    background: icon("icon-left-arrow", "svg");
  }
  &.icon-arrow-up-blue-active {
    background: icon("icon-arrow-up-blue-active", "svg");
  }
  &.icon-arrow-down-blue-active {
    background: icon("icon-arrow-down-blue-active", "svg");
  }
  &.icon-delete-grey {
    background: icon("icon-delete-grey", "svg");
  }
  &.icon-upload-grey {
    background: icon("icon-upload-grey", "svg");
  }
  &.icon-activate-green {
    background: icon("icon-activate-green", "svg");
  }
  &.icon-deactivate-red {
    background: icon("icon-deactivate-red", "svg");
  }
  &.icon-add-blue {
    background: icon("icon-add-blue", "svg");
  }
  &.icon-loading-small {
    background: icon("icon-loading-small", "svg");
  }
  &.icon-downloading-small {
    background: icon("icon-downloading-small", "svg");
  }
  &.icon-alert {
    background: icon("icon-alert", "svg");
  }
  &.icon-settings-blue {
    background: icon("icon-settings-blue", "svg");
  }
  &.icon-shipment-button {
    background: icon("icon-shipment-button", "svg");
  }
  &.icon-impact-button {
    background: icon("icon-impact-button", "svg");
  }
  &.icon-delete-dashboard {
    background: icon("icon-delete-blue", "svg");
  }
  &.icon-edit {
    background: icon("icon-edit", "svg");
  }
  &.icon-edit-gray {
    background: icon("icon-edit-gray", "svg");
  }
  &.icon-edit-blue {
    background: icon("icon-edit-blue", "svg");
  }
  &.icon-popup-info {
    background: icon("icon-popup-info", "svg");
  }
  &.icon-group-info {
    background: icon("icon-group-info", "svg");
  }
  &.icon-privacy {
    background: icon("icon-privacy", "svg");
  }
  &.icon-solid-black-down-arrow {
    background: icon("icon-solid-black-down-arrow", "svg");
  }
  &.icon-bayer-snow {
    background: icon("icon-bayer-snow", "svg");
  }
  &.icon-chevron {
    background: icon("icon-chevron", "svg");
  }
  &.icon-non-approved {
    background: icon("icon-non-approved", "svg");
  }
  &.icon-approved {
    background: icon("icon-approved", "svg");
  }
  &.icon-calender-blue {
    background: image("icon-calender-blue", "svg");
  }
  &.icon-calender-white {
    background: image("icon-calender-white", "svg");
  }
  &.icon-universe {
    background: image("icon-universe", "svg");
  }
  &.icon-target-list {
    background: image("icon-target-list", "svg");
  }
  &.icon-delivered {
    background: image("icon-delivered", "svg");
  }
  &.icon-engaged {
    background: image("icon-engaged", "svg");
  }
  &.icon-reached {
    background: image("icon-reached", "svg");
  }
  &.icon-blue-down-arrow {
    background: image("icon-blue-down-arrow", "svg");
  }
  &.icon-newsletter {
    background: image("icon-newsletter", "svg");
  }
  &.icon-approved-email {
    background: image("icon-approved-email", "svg");
  }
  &.icon-suggestion {
    background: image("icon-suggestion", "svg");
  }
  &.icon-hour-glass-red {
    background: icon("icon-hour-glass-red", "svg");
  }
  &.icon-hour-glass-green {
    background: icon("icon-hour-glass-green", "svg");
  }
  &.icon-information {
    background: icon("icon-information", "svg");
  }
  &.img-target-info {
    background: icon("icon-target-info", "svg");
  }
  &.img-target-info-active {
    background: icon("icon-target-info-hover", "svg");
  }
  &.img-alert-no-fill {
    background: icon("icon-alert-no-fill", "svg");
  }
  &.icon-cross {
    background: icon("icon-cross-gray", "svg");
  }
  &.icon-alert-circle {
    background: icon("icon-alert-circle", "svg");
  }
  &.icon-warning-yellow {
    background: icon("icon-warning-yellow", "svg");
  }  
  &.icon-close-summary{
    background: icon("icon-close-summary", "svg");
  }  
}
